<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Basic Information</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form v-if="show">
            <b-form-group label="Id:">
              <b-form-input
                type="text"
                v-model="patient.id"
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Study Group:" label-for="input-3">
              <b-form-select
                id="input-3"
                v-model="patient_group"
                :options="patient_groups"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Clinicial Reference:">
              <b-form-input
                type="text"
                v-model="patient.clinical_reference"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Username/Email:">
              <b-form-input
                type="email"
                v-model="patient.username_email"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Password:">
              <b-form-input
                type="password"
                v-model="new_password"
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Phone:">
              <b-form-input
                type="text"
                v-model="patient.phone"
                placeholder="E.g. +447903001234"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="First Name:">
              <b-form-input
                type="text"
                v-model="patient.firstname"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Last Name:">
              <b-form-input
                type="text"
                v-model="patient.lastname"
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Gender:">
              <b-form-select
                v-model="patient.gender"
                :options="gender_options"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Birthday:">
              <b-form-datepicker v-model="patient.birthday"></b-form-datepicker>
            </b-form-group>
            <b-form-group label="Last Activity:">
              <b-form-input
                type="text"
                v-model="patient.last_login"
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Concent:">
              <b-form-checkbox
                id="checkbox-1"
                v-model="patient.research_concent"
                name="checkbox-1"
              >
                Research Concent
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-2"
                v-model="patient.privacy_concent"
                name="checkbox-2"
              >
                Privacy Concent
              </b-form-checkbox>
              <!-- <b-form-checkbox id="checkbox-3" v-model="patient.cookie_concent" name="checkbox-3">
                Cookie Concent
              </b-form-checkbox> -->
            </b-form-group>
            <!-- <b-form-group label="Meta Data:">
                <b-form-textarea
                  id="meta-data-json-textfield"
                  v-model="patient.meta_data"
                  :disabled="user.userTypeId != 4"
                  rows="4"
                  max-rows="6"
                ></b-form-textarea>
            </b-form-group> -->
            <b-button @click="onUpdatePatient" variant="primary">Save</b-button>
            <b-button
              v-b-modal.modal-delete-patient-confirmation
              variant="danger"
              >Delete</b-button
            >
            <b-button @click="onResendAccessInfo" variant="info"
              >Resend Access</b-button
            >
          </b-form>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <b-card no-body>
        <b-tabs card v-model="activeTab">
          <b-tab title="Questionnaire Groups">
            <b-card-text>
              <b-table
                hover
                bordered
                :items="patient_entities"
                :fields="table_entities_fields"
                :sort-by.sync="q_sortBy"
                :sort-desc.sync="q_sortDesc"
                @row-clicked="
                  (item, index, event) => entityRowClickHandler(item)
                "
              >
                <template #cell(questionnaire_type)="data">
                  {{ getQuestionnaireTypeText(data.item.questionnaireId) }}
                </template>
                <template #cell(updatedAt)="data">
                  {{ formatDate(data.item.updatedAt) }}
                </template>
                <template #cell(status)="row">
                  <p>{{ statusCheck(row) }}</p>
                </template>
                <template #cell(actions)="row">
                  <b-button
                    v-b-modal.modal-delete-entity-confirmation
                    :disabled="statusCheck(row) != 'Empty'"
                    variant="danger"
                    size="sm"
                    class="mr-2"
                    @click="setRowToDelete(row)"
                    >X</b-button
                  >
                </template>
              </b-table>
              <b-form-group>
                <b-button v-b-modal.modal-create-group variant="primary"
                  >Create New</b-button
                >
                <b-button
                  v-b-modal.modal-auto-generate-questionaire
                  variant="success"
                  >Auto Generate</b-button
                >
              </b-form-group>
            </b-card-text>
          </b-tab>
          <b-tab title="Questionnaires" @click="resetSelectedEntity">
            <b-card-text>
              <b-table
                hover
                bordered
                :items="filteredPatientAnswers"
                :fields="table_questionnaires_fields"
                :sort-by.sync="q_sortBy"
                :sort-desc.sync="q_sortDesc"
                @row-clicked="
                  (item, index, event) => questionnaireRowClickHandler(item)
                "
              >
                <template #cell(questionnaire_type)="data">
                  {{ getQuestionnaireTypeText(data.item.questionnaireId) }}
                </template>
                <template #cell(symptoms_date)="data">
                  {{ formatDate(data.item.symptoms_date) }}
                </template>
                <template #cell(status)="row">
                  <p>{{ statusCheck(row) }}</p>
                </template>
                <template #cell(actions)="row">
                  <!-- v-b-modal.modal-delete-answer-confirmation -->
                  <!-- @click="setRowToDelete(row)" -->
                  <b-button
                    :disabled="statusCheck(row) != 'Empty'"
                    variant="danger"
                    size="sm"
                    class="mr-2"
                    @click="onDeleteQuestionnaireAnswer(row)"
                    >X</b-button
                  >
                </template>
              </b-table>
              <b-form-group>
                <b-button v-b-modal.modal-create-questionaire variant="primary"
                  >Create New</b-button
                >
                <b-button
                  v-b-modal.modal-auto-generate-questionaire
                  variant="success"
                  >Auto Generate</b-button
                >
              </b-form-group>
            </b-card-text>
          </b-tab>
          <b-tab title="Meta Configuration">
            <b-card-text>
              <b-form>
                <!-- <b-form-group 
                  label="Gen Password:"
                  description="Password.">
                  <b-form-input type="text" v-model="patient.meta_data.gen_password" disabled></b-form-input>
                </b-form-group> -->

                <b-form-group
                  label="Default Record Length (Days):"
                  description="Default setting for number of days to record symptoms. "
                >
                  <b-form-input
                    type="number"
                    v-model="patient.meta_data.default_tracking_length_days"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Start Date:"
                  description="Date when patient should start recording symptoms."
                >
                  <b-form-datepicker
                    v-model="patient.meta_data.start_tracking_datetime"
                  ></b-form-datepicker>
                </b-form-group>

                <b-form-group
                  label="End Date:"
                  description="Date when patient should end recording symptoms."
                >
                  <b-form-datepicker
                    v-model="patient.meta_data.end_tracking_datetime"
                    disabled
                  ></b-form-datepicker>
                </b-form-group>

                <b-form-group
                  label="Edit Window (Hours)"
                  description="Questionnaires must be submitted/edited in X hours from date of syndrome."
                >
                  <b-form-input
                    type="number"
                    v-model="patient.meta_data.submit_edit_window_hours"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Login Reminder Method"
                  description="Method that should be used to notify patient if inactive (no login) for X hours."
                >
                  <b-form-select
                    v-model="patient.meta_data.login_reminder_method"
                    :options="send_info_options"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label="Login Reminder (Hours)"
                  description="Patient will be reminded to login if inactivity (no login) for X hours."
                >
                  <b-form-input
                    type="number"
                    v-model="patient.meta_data.login_remind_patient_hours"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Login Reminder - Clinician(Hours)"
                  description="Clinician will be notified if patient is inactive (no login) for X hours."
                >
                  <b-form-input
                    type="number"
                    v-model="patient.meta_data.login_remind_clinician_hours"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Last Login Reminder (Patient):"
                  description="Last time patient being reminded to login."
                >
                  <b-form-input
                    type="text"
                    v-model="
                      patient.meta_data.last_login_remind_patient_datetime
                    "
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Last Login Reminder (Clinician):"
                  description="Last time clinician being reminded for inactivity."
                >
                  <b-form-input
                    type="text"
                    v-model="
                      patient.meta_data.last_login_remind_clinician_datetime
                    "
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Clinician Notes:"
                  description="Notes and scribbles."
                >
                  <b-form-textarea
                    v-model="patient.meta_data.clinician_notes"
                    placeholder="Clinician Notes ..."
                    rows="3"
                    max-rows="12"
                    no-auto-shrink
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <b-button @click="onUpdatePatient" variant="primary"
                    >Save</b-button
                  >
                </b-form-group>

                <!-- <p>{{patient.meta_data}}</p> -->
              </b-form>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <!-- begin::Modal -->
    <b-modal
      id="modal-view-questionnaire"
      title="View Questionnaire (Raw Data)"
    >
      <p>{{ questionnaire_answer_on_display }}</p>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" @click="onCloseViewQuestionnaire"> Close </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-delete-patient-confirmation" title="Delete Patient">
      <h4>Are you sure you want to delete this patient?</h4>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelDeletePatient"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onConfirmDeletePatient">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-delete-entity-confirmation" title="Delete Entity">
      <h4>Are you sure you want to delete this questionnaire Group?</h4>
      <p>This will delete all the data that belongs to this group</p>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelDeleteEntity"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onConfirmDeleteEntity">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-delete-answer-confirmation" title="Delete Answer">
      <h4>Are you sure you want to delete this questionnaire?</h4>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelDeleteAnswer"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onConfirmDeleteAnswer">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal
      id="modal-auto-generate-questionaire"
      title="Auto generate questionnaires"
    >
      <h4>
        Are you sure you want to auto generate questionnaires based on study
        configuration?
      </h4>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelAutoGenerateQuestionaires">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          size="sm"
          variant="success"
          @click="onAutoGenerateQuestionaires"
        >
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-create-group" title="Create Questionnaire Group">
      <b-form>
        <b-form-group label="Questionnaire Types">
          <b-form-checkbox-group
            id="new-submission-typepicker"
            v-model="new_group.ids"
            :options="questionaire_types"
            inline
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group label="Study Group">
          <b-form-select
            v-model="new_group.groupId"
            :options="patient_groups_id"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Description">
          <b-form-input
            v-model="new_group.description"
            placeholder="Enter description"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelCreateGroup"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onCreateGroup">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-create-questionaire" title="Create Questionnaire">
      <b-form>
        <b-form-group label="Questionnaire Type">
          <b-form-select
            id="new-submission-typepicker"
            v-model="new_questionaire.id"
            :options="questionaire_types"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Questionnaire Group">
          <b-form-input
            v-model="new_questionaire.entityId"
            placeholder="Enter ID. Leave blank to create new group"
            type="number"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Date of Symptoms">
          <b-form-datepicker
            id="new-submission-datepicker"
            v-model="new_questionaire.date"
          ></b-form-datepicker>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelCreateQuestionaire">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onCreateQuestionaire">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-send-access-info" title="Send Access Information">
      <b-form>
        <b-form-group label="Send Method">
          <b-form-select
            id="new-send-access-info-method"
            v-model="send_access_info.method"
            :options="send_info_options"
          ></b-form-select>
        </b-form-group>
        <!-- <b-form-group label="Send on which day?">
          <b-form-datepicker id="new-send-access-info-date" v-model="send_access_info.date" today-button locale="en"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Send at what time?">
          <b-form-timepicker id="new-send-access-info-time" v-model="send_access_info.time" now-button locale="en"></b-form-timepicker>
        </b-form-group> -->
      </b-form>
      <template #modal-footer="{ cancel, submit }">
        <b-button size="sm" @click="onCancelSendAccessInfo"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="success" @click="onSendAccessInfo">
          Send
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "patient",
  data() {
    return {
      show: true,
      // TODO: implement sorting of other fields when we press on the table header
      q_sortBy: "symptoms_date",
      q_sortDesc: true,
      // ----- Patient section -----
      gender_options: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      send_info_options: [
        { text: "Via Email & Phone", value: "Both" },
        { text: "Via Email only", value: "Email" },
        { text: "Via Phone only", value: "Phone" },
      ],
      send_access_info: {
        method: "Both",
        date: null,
        time: null,
      },
      patient: {
        id: "",
        username_email: "",
        firstname: "",
        lastname: "",
        Gender: "",
        birthday: "",
        research_concent: false,
        privacy_concent: false,
        cookie_concent: false,
        meta_data: {
          gen_password: "",
          clinician_notes: "",
        },
        groups: [],
      },
      patient_group: { name: "N/A" },
      // ----- Questionnaire section -----
      activeTab: 1,
      selectedEntityId: null,
      patient_answers: null,
      patient_entities: null,
      table_entities_fields: [
        // { key: "groupId", label: "Group Id" },
        { key: "id", label: "Id" },
        { key: "updatedAt", label: "Date Last Modified" },
        { key: "description", label: "Description" },
        { key: "actions", label: "Action" },
      ],
      table_questionnaires_fields: [
        // { key: "id", label: "Id" },
        // { key: "updatedAt", label: "Date Filled in" }, // TODO: check if we should use symptoms date or smth else?
        { key: "symptoms_date", label: "Effective Date" },
        { key: "questionnaire_type", label: "Type" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Action" },
      ],
      rowToDelete: null,
      new_password: null,
      clinician_notes: "",
      new_group: {
        description: "",
        groupId: null,
        // patientId: null,
        questionnaireIds: [],
      },
      new_questionaire: {
        id: null,
        entityId: null,
        date: null,
      },
      questionaire_types: [
        { value: -1, text: "No available questionnaires found" },
      ],
      questionnaire_answer_on_display: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    patient_groups() {
      return this.patient.groups.map((group) => ({
        text: group.name,
        value: group,
      }));
    },
    patient_groups_id() {
      return this.patient.groups.map((group) => ({
        text: group.name,
        value: group.id,
      }));
    },
    filteredPatientAnswers() {
      if (this.selectedEntityId === null) {
        return this.patient_answers; // Return all if no entity is selected
      }
      return this.patient_answers.filter(
        (answer) => answer.entityGroupId === this.selectedEntityId
      );
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
    selectedEntityId(newVal) {
      this.new_questionaire.entityId = newVal;
    },
    patient_group(newVal) {
      this.new_group.groupId = newVal.id;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Patients" }]);
    this.refresh();
  },
  methods: {
    refresh() {
      ApiService.get("/users/patient/" + this.$route.params.id).then(
        ({ data }) => {
          this.patient = data.data;
          // TODO: handle default group from the route. (if coming from group page use that, if coming from patient page then unselected)
          // TODO: filter questionnaire data by group selected
          this.patient_group = this.patient.groups[0];
        }
      );

      ApiService.get(
        "/questionnaires/answers/patient/" + this.$route.params.id
      ).then(({ data }) => {
        this.patient_answers = data.data;
      });
      // .catch(({ error }) => {
      //   console.log("Failed to fetch answers", error);
      // });

      ApiService.get("/entities/users/" + this.$route.params.id).then(
        ({ data }) => {
          this.patient_entities = data.data;
        }
      );
      // .catch(({ error }) => {
      //   console.log("Failed to fetch answers", error);
      // });

      ApiService.get("/questionnaires/types/").then(({ data }) => {
        this.questionaire_types = data.data.map((questionnaire) => ({
          value: questionnaire.id,
          text: questionnaire.name,
        }));
      });
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    statusCheck(row) {
      if (row.item.answer_json == null) {
        return "Empty";
      } else {
        return "Answered";
      }
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed
      const day = String(date.getDate()).padStart(2, "0");
      return `${day}-${month}-${year}`;
    },
    getQuestionnaireTypeText(questionnaireId) {
      const matchedType = this.questionaire_types.find(
        (i) => i.value === questionnaireId
      );
      return matchedType ? matchedType.text : "N/A";
    },
    onCancelDeletePatient(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-delete-patient-confirmation");
    },
    onCancelDeleteEntity(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-delete-entity-confirmation");
    },
    onCancelDeleteAnswer(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-delete-answer-confirmation");
    },
    onCloseViewQuestionnaire(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-view-questionnaire");
    },
    entityRowClickHandler(item) {
      this.activeTab = 1;
      this.selectedEntityId = item.id;
    },
    resetSelectedEntity() {
      this.selectedEntityId = null;
    },
    questionnaireRowClickHandler(item) {
      this.questionnaire_answer_on_display = item.answer_json;
      this.$bvModal.show("modal-view-questionnaire");
      // TODO: redirect to Frontend Questionnaire results page
      // need to be added to database
    },
    onConfirmDeletePatient() {
      ApiService.delete("/users/patient/" + this.$route.params.id)
        .then(() => {
          // Success
          this.$router.push("/");
        })
        .catch(() => {
          // Failure
          alert("Error: Delete patient failed.");
        });
    },
    setRowToDelete(row) {
      this.rowToDelete = row;
    },
    onConfirmDeleteAnswer() {
      const row = this.rowToDelete;
      this.onDeleteQuestionnaireAnswer(row);
    },
    onConfirmDeleteEntity() {
      const row = this.rowToDelete;
      ApiService.delete("/entities/" + row.item.id)
        .then(() => {
          this.$bvModal.hide("modal-delete-entity-confirmation");
          this.refresh();
        })
        .catch(() => {
          alert("Error: Failed to delete questionnaire group.");
        });
    },
    onCancelCreateGroup(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-create-group");
    },
    onCancelCreateQuestionaire(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-create-questionaire");
    },
    onCreateGroup() {
      // console.log("creating group");
      // console.log("group", this.new_group);
      ApiService.post(
        "/entities/users/" +
          this.$route.params.id +
          "/groups/" +
          this.new_group.groupId,
        {
          description: this.new_group.description,
          questionnaireIds: this.new_group.ids,
        }
      )
        .then(() => {
          this.$bvModal.hide("modal-create-group");
          this.refresh();
        })
        .catch(() => {
          alert("Error creating new questionnaire entry.");
        });
    },
    onCreateQuestionaire() {
      ApiService.post(
        "/questionnaires/answers/patient/" + this.$route.params.id,
        {
          questionnaireId: this.new_questionaire.id,
          entityId: this.new_questionaire.entityId,
          symptomsDate: this.new_questionaire.date,
        }
      )
        .then(() => {
          // Success
          this.$bvModal.hide("modal-create-questionaire");
          this.refresh();
        })
        .catch(() => {
          // Failure
          alert("Error creating new questionnaire entry.");
        });
    },
    onDeleteQuestionnaireAnswer(row) {
      if (row.item.answer_json == null) {
        ApiService.delete(
          "/questionnaires/answers/patient/" +
            this.$route.params.id +
            "/" +
            row.item.id
        )
          .then(() => {
            // Success
            this.refresh();
          })
          .catch(() => {
            // Failure
            alert("Error: Failed to delete questionnaire.");
          });
      }
    },
    onCancelAutoGenerateQuestionaires(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-auto-generate-questionaire");
    },
    // TODO: test this
    onAutoGenerateQuestionaires() {
      ApiService.post(
        "/questionnaires/answers/autogen/patient" + this.$route.params.id
      )
        .then(() => {
          // Success
          this.$bvModal.hide("modal-auto-generate-questionaire");
          this.refresh();
        })
        .catch(() => {
          // Failure
          alert("Error auto generating new questionnaires.");
        });
    },
    onCancelSendAccessInfo(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-send-access-info");
    },
    onResendAccessInfo() {
      this.send_access_info = {
        method: "Both",
        date: null,
        time: null,
      };
      this.$bvModal.show("modal-send-access-info");
    },
    onSendAccessInfo() {
      // console.log(this.send_access_info);
      // var send_datetime = new Date(this.send_access_info.date + "T" + this.send_access_info.time);
      // console.log(datetime);
      ApiService.post(
        "/users/patient/" + this.$route.params.id + "/send-access",
        {
          send_access_info_option: this.send_access_info.method,
        }
      )
        .then(() => {
          // Success
          alert("Success: Resent access information");
          this.$bvModal.hide("modal-send-access-info");
        })
        .catch(() => {
          // Failure
          alert("Error: Failed to resend access information");
        });
    },
    onUpdatePatient() {
      // if (this.patient.meta_data.start_tracking_datetime != null) {
      //   this.patient.meta_data.end_tracking_datetime = this.addDays(
      //     this.patient.meta_data.start_tracking_datetime,
      //     this.patient.meta_data.default_tracking_length_days
      //   );
      //   this.patient.meta_data.start_tracking_datetime = new Date(
      //     this.patient.meta_data.start_tracking_datetime
      //   );
      // }
      ApiService.put("/users/patient/" + this.$route.params.id, {
        patientData: this.patient,
        new_password: this.new_password,
      })
        .then(() => {
          // Success
          alert("Success: Patient information updated.");
          // console.log(data);
          this.new_password = "";
          this.refresh();
        })
        .catch(() => {
          // Failure
          this.new_password = "";
          alert("Error: Update patient information failed.");
        });
    },
  },
};
</script>
